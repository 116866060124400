.valida-credencial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
  text-align: center;
  padding: 20px;
}

.credencial-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

.home-link-image {
  margin-top: 20px;
  max-width: 100px;  
  height: auto;
  cursor: pointer;  
}