.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  text-align: center;
  padding: 20px;
}

.logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.info-text {
  margin-bottom: 20px;
}

.info-link .link {
  display: block;
  text-decoration: none;
  text-align: center;
}

h3 {
  margin: 0 0 10px 0;
  font-size: 1.5rem;
  color: #555;
}

p {
  margin: 0;
  font-size: 1.2rem;
  color: #666;
}

@media (min-width: 768px) {
  .info-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .info-text {
    margin-bottom: 0;
    margin-right: 20px;
  }
}
